import {ref} from "vue"
import i18next from 'i18next';

import enKeys from "./translations/en"
import frKeys from "./translations/fr"

let initialized = false;
let longTranslations = {};


export const currentLanguage = ref('fr');
export const supportedLanguage = ["en", "fr"]; // ISO 639-1 Language Codes

const missingKeys = {};

export async function initialize() {
    if (initialized) return;
    initialized = true;

    let currentLanguageAPI = "fr";
    currentLanguage.value = currentLanguageAPI;

    // TODO : set cimode as lng value (take the key value as text)
    await i18next.init({
        lng: currentLanguageAPI,
        defaultNS: 'translation',
        fallbackNS: ['module'],
        debug: false,
        keySeparator: '>',
        nsSeparator: '|',
        resources: {
            en: { translation: (await import("./translations/en.js")).default },
            fr: { translation: (await import("./translations/fr.js")).default },
        },
        saveMissing: true,
        missingKeyHandler: async (lngs, ns, key, fallbackValue, updateMissing, options) => {
            const val = `${key}`;
            if (!missingKeys[val]) {
                console.log(`%cMissing key : %c${val}`, "color: red", "font-weight: bold;");
                missingKeys[val] = true;
            }
        }
    });

    LoadTranslation(currentLanguage.value); 
}

declare module '@vue/runtime-core' {
    interface ComponentCustomProperties {
        t: (value: keyof typeof enKeys & keyof typeof frKeys, options?: any) => string,
        translate: typeof translate,
    }
}

export function t(index: (keyof typeof enKeys) & (keyof typeof frKeys), options?: any) : string {
    return i18next.t(index, options);
}


export function tIndex(index) {
    return longTranslations[index][i18next.language];
}

export function translate(translation, defaultValue = "Translation Error", overrideLang = undefined){
    if (!translation) return defaultValue;
    return translation[overrideLang ?? (currentLanguage.value ?? 'fr')];
}

(window as any).t = t;
(window as any).tIndex = tIndex;
(window as any).translate = translate;

export function loadTranslationSystem(app) {
    app.mixin({
        methods: {
            t,
            tIndex,
            translate
        }
    })
}

export async function setLanguage(lang) {
    currentLanguage.value = lang;
    await LoadTranslation(lang)
    await i18next.changeLanguage(lang);
}

export async function AddTranslation(lang, namespace, translations) {
    i18next.addResourceBundle(lang, namespace, translations);
}

export async function LoadTranslation(lang) {
    try{
        i18next.addResourceBundle(lang, 'translation', (await import(`./translations/${lang}.js`)).default);
    }catch(e){}
}

(window as any).AddTranslation = AddTranslation;